<!-- =========================================================================================
  Author: Nada Azzam
========================================================================================== -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            name="company_password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="company_password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="company_password"
              class="w-full mt-6"
            />
          </ValidationProvider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="update"
            class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <vx-card
      ref="filterCard"
      title="Updating new company"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <div class="vx-row">
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY NAME
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="company_name"
                    rules="required|min:2"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-terminal"
                      icon-no-border
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      v-model="company_name"
                    />
                  </validation-provider>
                </div>

                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY CODE
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="company_code"
                    :rules="{
                      required: true,
                      min: 1
                    }"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-hash"
                      icon-no-border
                      v-model="company_code"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Upload Image
                  </label>
                  <div v-if="!isUserUpdateFile" class="con-img-upload">
                    <!-- <div class="con-input-upload"> -->
                    <vs-images>
                      <vs-image :src="company_image" />
                    </vs-images>
                  </div>
                  <vs-upload ref="getFile" @change="getFile" limit="1" />

                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="openPopUp()"
            class="mb-2"
            >SUBMIT</vs-button
          >
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>

import axios from "@/axios.js";
import endpoints from "@/endpoints";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty"
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters"
});

// Store Module

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      company_name: null,
      company_code: null,
      company_image: null,
      fileRequired: false,
      company_password: null,
      popupActive: false,
      isUserUpdateFile: false

      // Cell Renderer Components
    };
  },
  watch: {
    company_name(obj) {
      if (obj.length <= 2) {
        var res = obj.substring(0, 2);
        var res1 = res.toUpperCase();
        this.company_code = res1 + "_";
      }
    }
  },
  computed: {
    itemsData() {
      return this.$store.state.hrReportManagement.items;
    }
  },
  methods: {
    openPopUp() {
      this.popupActive = true;
    },
    getFile() {
      this.company_image = this.$refs.getFile.filesx[0];
      this.isUserUpdateFile = true;
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.company_name = null;
    },
    update() {
      if (!this.company_image) {
        this.$vs.notify({ color: "danger", title: "File is Required" });
      } else {
        this.$vs.loading();
        const formData = new FormData();
        formData.append("name", this.company_name);
        formData.append("code", this.company_code);

        if(this.company_image['type'] ){
           formData.append(
          "image",this.company_image
        ); 
        }
        
       
        
        formData.append("_method", "put");
        formData.append("password", this.company_password);

        axios
          .post(
            `${endpoints.COMPANIES_ENDPOINT}/${this.$route.query.SID}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(response => {
            this.$vs.loading.close();
            if (response.status !== 200) {
              this.$vs.notify({
                title: "Error",
                text: "Company is not updated",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            } else {
              this.$vs.notify({
                title: "Success",
                text: "Company is updated",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
              this.popupActive = false;
              this.$router.push("/GetAll/Company");
            }
            this.popupActive = false;
          })
          .catch(error => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          });
        this.popupActive = false;
      }
    },
    getById() {
      axios
        .get(`${endpoints.COMPANIES_ENDPOINT}/${this.$route.query.SID}`)
        .then(res => {
          const x = res.data.response.data;
          this.company_name = x.name;
          this.company_code = x.code;
          this.company_image = x.image;
        });
    }
  },
  mounted() {
    this.getById();
  },
  created() {}
};
</script>

<style>
.vs-image .con-vs-image {
  width: 200px;
  height: 200px;
  min-height: 200px;
  overflow: auto;
}
.vs-images-hover-default .vs-image:hover .vs-image--img {
  transform: unset;
}
</style>
